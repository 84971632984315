@charset "UTF-8";
@import url(./fonts.css);
@import url(./pages.css);
@import url(./global.css);
@import url(./buttons.css);
.app {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  color: var(--text-color);
  transition: var(--transition);
  font-family: var(--primary-font);
  background-color: var(--site-bg-color);
  /* ---- ---- Páginas ---- ---- */
  /* ---- ---- Componentes ---- ---- */
}
.app .container {
  display: flex;
  margin: 0 20px;
  width: var(--max-width);
}
.app .logo {
  display: flex;
  max-width: 200px;
  align-items: center;
  text-decoration: none;
}
.app .logo svg,
.app .logo img {
  height: 26px;
  pointer-events: none;
  transition: var(--transition);
}
.app hr {
  width: 100%;
  height: 1px;
  border: none;
  margin: 8px 0px;
  transition: var(--transition);
  background-color: var(--border-color);
}
.app .grid {
  gap: 8px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(12, 1fr);
}
.app table {
  width: 100%;
  overflow: hidden;
  border-spacing: 0px;
  border-collapse: collapse;
  background-color: white;
  border: 1px solid var(--border-color);
}
.app table th {
  height: 40px;
  text-align: left;
  padding: 0px 10px;
  font: bold 14px var(--primary-font);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.app table th:last-child {
  border-right: none;
}
.app table td {
  height: 40px;
  text-align: left;
  overflow: hidden;
  text-wrap: nowrap;
  padding: 0px 10px;
  text-overflow: ellipsis;
  color: var(--inactive-color);
  font: normal 12px var(--primary-font);
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.app table td img {
  width: 80px;
  aspect-ratio: 1/1;
}
.app table td a {
  padding: 0 !important;
  text-decoration: none;
  color: var(--primary-color);
  color: var(--inactive-color);
}
.app table td a:hover {
  text-decoration: underline;
}
.app table td:last-child {
  border-right: none;
}
.app table tbody tr:last-child td {
  border-bottom: none;
}
.app div.loading {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font: normal 14px var(--font-hand);
}
.app .select-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.app .select-wrapper svg {
  right: 12px;
  position: absolute;
  pointer-events: none;
}
.app .select-wrapper select,
.app .select-wrapper input {
  width: 222px;
  height: 34px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding-left: 12px;
  letter-spacing: 0.4px;
  background-color: white;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  font: normal 13px var(--font-display);
}
.app .select-wrapper select[type=text],
.app .select-wrapper input[type=text] {
  height: 30px !important;
}
.app .select-wrapper select:focus-visible,
.app .select-wrapper input:focus-visible {
  outline: none;
}
.app .select-wrapper #iconFilter {
  display: none;
}
.app .preview {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
  border-bottom: 1px dashed var(--border-color);
}
.app .preview .music-card {
  margin-bottom: 20px;
  min-width: 300px !important;
}
.app .form {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  flex-direction: column;
}
.app .form .block {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .form .block .title {
  display: none;
  font: bold 22px var(--font-display);
}
.app .form .block label {
  min-width: 80px;
}
.app .form .block input,
.app .form .block select {
  width: 100%;
  height: 34px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0px 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: white;
  font: normal 12px var(--font-display);
  border: 1px solid var(--border-color);
}
.app .form .block input:focus-visible,
.app .form .block select:focus-visible {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0px 0px 8px var(--primary-color);
}
.app .form .block .select {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.app .form .block .select svg {
  right: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
}
.app .form .block .policy {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-wrap: balance;
  text-align: center;
  font: normal 11px var(--font-display);
  line-height: 2;
}
.app .form .block svg,
.app .form .block .icon {
  right: 14px;
  position: absolute;
}
.app .faqs {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .faqs .question {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .faqs .question b {
  text-wrap: balance;
  text-align: center;
  font: bold 14px var(--font-display);
  line-height: 2;
}
.app .faqs .question p {
  text-wrap: balance;
  text-align: center;
  font: normal 12px var(--font-display);
  line-height: 2;
}
.app .top-bar {
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 30px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  background-color: var(--banner-bg-color);
}
.app .top-bar .container {
  align-items: center;
  justify-content: center;
}
.app .top-bar .container .flex-message {
  width: 400px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .top-bar .container .flex-message .message {
  opacity: 0;
  height: 100%;
  width: 400px;
  display: flex;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  text-wrap: nowrap;
  align-items: center;
  letter-spacing: 0.4px;
  min-width: -moz-max-content;
  min-width: max-content;
  justify-content: center;
  color: var(--inactive-color);
  transition: var(--transition);
  font: normal 11px var(--primary-font);
}
.app .top-bar .container .flex-message .message:hover {
  color: white;
}
.app .top-bar .container .flex-message .message.show {
  opacity: 1;
  visibility: visible;
}
.app .top-bar .container .flex-message a {
  text-decoration: none;
  color: var(--inactive-color);
}
.app .top-bar .container .flex-message a:hover {
  color: white;
  text-decoration: underline;
}
.app .header {
  left: 0;
  top: 30px;
  z-index: 12;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  background-color: var(--header-bg-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.app .header .container {
  height: 34px;
  align-items: center;
  justify-content: space-between;
}
.app .header .container .flex {
  gap: 14px;
  width: 100%;
  display: flex;
  align-items: center;
}
.app .header .container .flex .nav-menu {
  height: 80px;
  display: flex;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list {
  gap: 14px;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  list-style: none;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item {
  height: 80px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link {
  height: 100%;
  display: flex;
  padding: 0 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  letter-spacing: 0.4px;
  text-decoration: none;
  min-width: -moz-max-content;
  min-width: max-content;
  text-transform: capitalize;
  transition: var(--transition);
  font: normal 16px var(--primary-font);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link:hover {
  text-decoration: underline;
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link svg {
  display: none;
}
.app .header .container .flex hr {
  width: 1px;
  height: 80px;
  border: none;
  transition: var(--transition);
  background-color: var(--border-color);
}
.app .header .container .flex .icon-button {
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .header .container .flex:nth-child(1) {
  justify-content: flex-start;
}
.app .header .container .flex:nth-child(1) .icon-button {
  display: none;
}
.app .header .container .flex:nth-child(2) {
  justify-content: flex-end;
}
.app .header .container .flex:last-child {
  gap: 20px;
  justify-content: flex-end;
}
.app .menu {
  top: 0px;
  z-index: 16;
  width: 300px;
  right: -300px;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  box-shadow: var(--elevated);
  transition: var(--transition);
  justify-content: space-between;
  background-color: var(--site-bg-color);
  border-left: 1px solid var(--border-color);
}
.app .menu .menu-header {
  height: 80px;
  display: flex;
  text-indent: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
  align-items: center;
  transition: var(--transition);
  justify-content: space-between;
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--border-color);
}
.app .menu .menu-header .menu-title {
  letter-spacing: 0.4px;
  font: bold 16px var(--primary-font);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .menu .menu-header .icon-button {
  right: 50px;
  position: absolute;
}
.app .menu .menu-navigator {
  width: 100%;
  height: calc(100vh - 81px);
  display: flex;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
}
.app .menu .menu-navigator .nav-menu {
  width: 100%;
}
.app .menu .menu-navigator .nav-menu .nav-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item {
  gap: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  transition: var(--transition);
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item .nav-link {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: flex-start;
  font: normal 14px var(--font-display);
  color: light-dark(#757575, #999999);
  transition: var(--transition);
  text-decoration: none;
  text-indent: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item .nav-link svg {
  right: 50px;
  position: absolute;
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item .nav-link:active {
  text-decoration: underline;
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item:hover {
  background-color: #f5f5f5;
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item:hover .nav-link {
  color: var(--text-color);
}
.app .menu .menu-navigator .nav-menu .nav-list .nav-item.hidden {
  text-decoration: line-through;
}
.app .menu.show {
  right: 0;
}
.app .language {
  top: -180px;
  opacity: 0;
  z-index: 16;
  width: 300px;
  display: flex;
  padding: 18px;
  position: fixed;
  visibility: hidden;
  border-radius: 12px;
  flex-direction: column;
  box-shadow: var(--elevated);
  transition: var(--transition);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.app .language .language-select {
  display: flex;
  position: relative;
  align-items: center;
}
.app .language .language-select select {
  width: 100%;
  height: 34px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-indent: 10px;
  border-radius: 4px;
  letter-spacing: 0.4px;
  transition: var(--transition);
  font: normal 12px var(--primary-font);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.app .language .language-select button {
  right: 1px;
  height: 30px;
  position: absolute;
  pointer-events: none;
  transition: var(--transition);
  color: light-dark(var(--color-black-18), var(--color-white-fa));
}
.app .language.show {
  top: 180px;
  opacity: 1;
  visibility: visible;
}
.app .main {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-top: 112px;
  padding-bottom: 80px;
}
.app .main .section,
.app .main section {
  z-index: 5;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.app .main .section .container,
.app .main section .container {
  flex-direction: column;
}
.app .main .section.m-top,
.app .main section.m-top {
  margin-top: -40px;
}
.app .main .background {
  inset: 0;
  top: 112px;
  z-index: 1;
  min-height: 100vh;
  position: absolute;
  filter: blur(50px);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  transition: var(--transition);
}
.app .main .background.mask {
  -webkit-mask-image: var(--mask);
          mask-image: var(--mask);
}
.app .main .background.show {
  filter: blur(0) !important;
}
.app .main .background-i {
  z-index: 3;
  width: 100%;
  display: none;
  min-height: 100vh;
  filter: blur(50px);
  position: absolute;
  transform: scaleX(-1);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  transition: var(--transition);
}
.app .main .background-i.show {
  filter: blur(0) !important;
}
.app .main .background-ii {
  z-index: 3;
  width: 100%;
  display: none;
  min-height: 100vh;
  filter: blur(50px);
  position: absolute;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  transition: var(--transition);
}
.app .main .background-ii.show {
  filter: blur(0) !important;
}
.app .main .background-glass {
  inset: 0;
  z-index: 4;
  position: absolute;
  background-color: var(--glass-color);
}
.app .footer {
  z-index: 4;
  width: 100%;
  display: flex;
  padding: 32px 0;
  justify-content: center;
  transition: var(--transition);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--footer-bg-color);
}
.app .footer .container {
  flex-direction: column;
}
.app .footer .container .footer-newsletter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.app .footer .container .footer-newsletter .form {
  gap: 8px;
  width: 40%;
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  flex-direction: column;
}
.app .footer .container .footer-newsletter .form .block {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.app .footer .container .footer-newsletter .form .title {
  margin-bottom: 14px;
  display: flex !important;
  font: normal 20px var(--font-display);
}
.app .footer .container .footer-newsletter .form .policy {
  color: #aaaaaa;
}
.app .footer .container .footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .footer .container .footer-socials .social-icons {
  gap: 15px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding-bottom: 32px;
  justify-content: center;
}
.app .footer .container .footer-socials .social-icons .social {
  width: 28px;
  height: 28px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .footer .container .footer-socials .social-icons .social a {
  inset: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.app .footer .container .footer-socials .social-icons .social svg {
  font-size: 22px;
}
.app .footer .container .footer-copyright {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
}
.app .footer .container .footer-copyright .copyright {
  display: flex;
  color: #757575;
  align-items: center;
  font: normal 11px var(--font-display);
}
.app .footer .container .footer-copyright .copyright svg {
  font-size: 9px;
  margin-right: 4px;
}
.app .footer .container .footer-copyright .policies-links {
  display: flex;
}
.app .footer .container .footer-copyright .policies-links .policies {
  gap: 14px;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.app .footer .container .footer-copyright .policies-links .policies .policy-item {
  min-width: -moz-max-content;
  min-width: max-content;
}
.app .footer .container .footer-copyright .policies-links .policies .policy-link {
  cursor: pointer;
  color: #757575;
  letter-spacing: 0.2px;
  min-width: -moz-max-content;
  min-width: max-content;
  text-decoration: underline;
  transition: var(--transition);
  font: normal 11px var(--font-display);
}
.app .footer .container .footer-copyright .policies-links .policies .policy-link:hover {
  color: var(--primary-color);
}
.app .cookies {
  top: 180px;
  opacity: 0;
  z-index: 20;
  width: 600px;
  display: flex;
  margin: 0 32px;
  position: fixed;
  overflow: hidden;
  padding: 18px 0px;
  visibility: hidden;
  border-radius: 18px;
  justify-content: center;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  background-color: var(--white-color);
}
.app .cookies .container {
  flex-direction: column;
}
.app .cookies .container hr {
  margin: 0px;
  border: none;
  background: none;
  border-top: 1px dashed var(--border-color);
}
.app .cookies .container .cookies-header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  flex-direction: row;
  justify-content: space-between;
}
.app .cookies .container .cookies-header .cookies-flex {
  width: 100%;
  display: flex;
  align-items: center;
}
.app .cookies .container .cookies-header .cookies-flex .cookies-title {
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: center;
  font: bold 14px var(--font-display);
}
.app .cookies .container .cookies-header .cookies-flex .cookies-button {
  width: 34px;
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}
.app .cookies .container .cookies-header .cookies-flex:last-child {
  justify-content: flex-end;
}
.app .cookies .container .cookies-content {
  gap: 14px;
  display: flex;
  padding: 14px 0px;
  flex-direction: column;
}
.app .cookies .container .cookies-content .cookies-box {
  display: flex;
}
.app .cookies .container .cookies-content .cookies-box .cookies-box-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app .cookies .container .cookies-content .cookies-box .cookies-box-data .cookies-box-title {
  width: 100%;
  font: bold 12px var(--font-display);
  line-height: 2.5;
}
.app .cookies .container .cookies-content .cookies-box .cookies-box-data .cookies-box-info {
  font: normal 12px var(--font-display);
  line-height: 1.5;
}
.app .cookies .container .cookies-content .cookies-box .cookies-box-control {
  display: flex;
  min-width: 40px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.app .cookies .container .cookies-action {
  gap: 8px;
  display: flex;
  margin-top: 14px;
  align-items: center;
  justify-content: space-between;
}
.app .cookies .container .cookies-action .button {
  width: 100% !important;
}
.app .cookies .container .cookies-action .button.show {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.app .cookies.show {
  opacity: 1;
  visibility: visible;
}
.app .lyrics-page {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.app .lyrics-page .lyrics {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .lyrics-page .lyrics h1 {
  width: 100%;
  margin: 0 0 30px;
  text-align: center;
  text-wrap: balance;
  font: bold 30px var(--primary-font);
}
.app .lyrics-page .lyrics .lyric {
  width: 100%;
}
.app .lyrics-page .lyrics .lyric p {
  text-align: center;
  font: normal 14px/2 var(--primary-font);
}
.app .lyrics-page .lyrics .lyric p span {
  color: #757575;
  font-weight: bold;
}
.app .lyrics-page .composers {
  gap: 10px;
  width: 50%;
  display: flex;
  padding: 20px 0px;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid var(--border-color);
}
.app .lyrics-page .composers h3,
.app .lyrics-page .composers p {
  margin: 0;
}
.app .lyrics-page .composers h3 {
  font: bold 18px/2 var(--primary-font);
}
.app .lyrics-page .composers p {
  font: normal 14px/2 var(--primary-font);
}
.app .lyrics-page .row-action {
  display: none;
}
.app .banner {
  margin-top: -30px;
  width: var(--wrapper) !important;
  max-width: 100%;
  height: calc(100vh - 80px - 30px);
  position: relative;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #cdcdcd;
}
.app .banner .slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 300%;
}
.app .banner .slides .slide {
  flex: 1;
  height: 770px;
  display: flex;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-align: center;
}
.app .banner .slides .slide .content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.7);
}
.app .banner .slides .slide .content .cover {
  aspect-ratio: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  border: 1px solid #242424;
}
.app .banner .slides .slide .content .title {
  margin: 20px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  font: bold 2rem var(--font-display);
}
.app .banner .slides .slide .content .subtitle {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-bottom: 20px;
  pointer-events: none;
  font: normal 1.2rem var(--font-display);
}
.app .banner .slides .slide .content .actions {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .banner .slides .slide .content .actions button {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font: normal 1rem var(--font-display);
  transition: background-color 0.3s ease;
}
.app .banner .slides .slide .content .actions button:hover {
  background-color: white;
}
.app .banner .controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}
.app .banner .controls button {
  opacity: 0;
  width: 36px;
  height: 36px;
  margin: 0 16px;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 8px;
  transition: var(--transition);
  border: 1px solid rgba(0, 0, 0, 0.7);
}
.app .banner .controls button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.app .banner .stop-btn {
  opacity: 0;
  width: 20px;
  height: 20px;
  visibility: hidden;
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  border-radius: 8px;
  color: var(--white-color);
  transition: var(--transition);
}
.app .banner:hover .controls button {
  opacity: 1;
  visibility: visible;
}
.app .banner:hover .stop-btn {
  opacity: 1;
  visibility: visible;
}
.app .block-title {
  gap: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 40px 0px 30px;
  flex-direction: column;
  justify-content: center;
}
.app .block-title h1 {
  margin: 0;
  font: bold 22px var(--font-display);
}
.app .block-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .block-content .container {
  gap: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .row-title,
.app .row-action {
  display: flex;
  padding: 40px 0px;
  align-items: center;
  flex-direction: row;
}
.app .row-title {
  justify-content: space-between;
}
.app .row-title h2 {
  width: 100%;
  margin: 0px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font: bold 28px var(--primary-font);
}
.app .row-title .flex-action {
  gap: 8px;
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app .row-action {
  justify-content: flex-end;
}
.app .article {
  width: 100%;
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
}
.app .article h1 {
  width: 100%;
  text-align: center;
  margin: 0px 0px 40px;
  font: normal 30px var(--font-display);
}
.app .article p {
  width: 100%;
  text-align: justify;
  font: normal 12px var(--font-display);
  color: var(--text-color);
  line-height: 2;
}
.app .music-card {
  grid-column: span 3;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}
.app .music-card .music-card-link {
  text-decoration: none;
}
.app .music-card .music-card-cover {
  width: 100%;
  display: flex;
  color: white;
  overflow: hidden;
  aspect-ratio: 1/1;
  position: relative;
  align-items: center;
  justify-content: center;
  font: bold 18px var(--primary-font);
  border: 1px solid var(--border-color);
}
.app .music-card .music-card-cover .music-cover {
  width: 100%;
  height: 100%;
  display: flex;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1;
  align-items: center;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  justify-content: center;
  transition: var(--transition);
}
.app .music-card .music-card-cover .music-card-songs {
  gap: 4px;
  right: 8px;
  bottom: 8px;
  height: 20px;
  display: flex;
  cursor: pointer;
  padding: 1px 6px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 43, 43, 0.6705882353);
}
.app .music-card .music-card-cover .music-card-songs .music-card-songs-icon {
  width: 12px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .music-card .music-card-cover .music-card-songs .music-card-songs-icon img {
  height: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.app .music-card .music-card-cover .music-card-songs .music-card-songs-text {
  letter-spacing: 0.4px;
  color: var(--color-white-ff);
  font: normal 12px var(--primary-font);
}
.app .music-card .music-card-caption {
  gap: 6px;
  width: 100%;
  display: flex;
  margin-top: 14px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .music-card .music-card-caption .music-card-title {
  width: 100%;
  line-clamp: 2;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  letter-spacing: 0.4px;
  -webkit-line-clamp: 2;
  font: bold 14px var(--primary-font);
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.app .music-card .music-card-caption .music-card-type {
  color: #aaaaaa;
  letter-spacing: 0.4px;
  font: normal 12px var(--primary-font);
}
.app .music-card .music-screen {
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  aspect-ratio: 1/1;
  position: absolute;
  transition: var(--transition);
  background-color: transparent;
}
.app .music-card-load {
  grid-column: span 3;
  display: flex;
  min-width: 100%;
  flex-direction: column;
  margin-bottom: 14px;
}
.app .music-card-load .music-load-cover {
  width: 100%;
  aspect-ratio: 1/1;
  animation: var(--loading);
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .music-card-load .music-load-details {
  gap: 8px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  align-items: flex-start;
}
.app .music-card-load .music-load-details .music-load-title {
  width: 140px;
  height: 14px;
  display: flex;
  animation: var(--loading);
  border: 1px solid var(--border-color);
}
.app .music-card-load .music-load-details .music-load-type {
  width: 200px;
  height: 12px;
  display: flex;
  animation: var(--loading);
  border: 1px solid var(--border-color);
}
.app .video-card {
  display: flex;
  min-width: 100%;
  cursor: pointer;
  grid-column: span 4;
  flex-direction: column;
  transition: var(--transition);
  margin-bottom: 14px;
}
.app .video-card a {
  color: inherit;
  text-decoration: none;
}
.app .video-card .video-card-cover {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.app .video-card .video-card-cover .video-card-link {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .video-card .video-card-cover .video-cover-back {
  z-index: 2;
  width: 100%;
  opacity: 0.7;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -o-object-fit: cover;
     object-fit: cover;
  filter: blur(30px);
  position: absolute;
  align-items: center;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  justify-content: center;
}
.app .video-card .video-card-cover .video-cover {
  width: auto;
  z-index: 3;
  height: 100%;
  display: flex;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  font: normal 12px var(--primary-font);
}
.app .video-card .video-card-cover .video-order {
  top: 8px;
  right: 8px;
  z-index: 4;
  display: flex;
  padding: 2px 8px;
  position: absolute;
  align-items: center;
  color: var(--color-white-ff);
  font: normal 12px var(--primary-font);
  box-shadow: 2px 2px 8px rgba(53, 53, 53, 0.3019607843);
}
.app .video-card .video-card-cover .background {
  inset: 0 !important;
  z-index: 1;
  filter: none;
  display: flex;
  min-height: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}
.app .video-card .video-card-cover .background .title {
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font: bold 18px var(--primary-font);
}
.app .video-card .video-card-title {
  gap: 6px;
  display: flex;
  padding-top: 14px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}
.app .video-card .video-card-title .video-title {
  width: 100%;
  line-clamp: 2;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  letter-spacing: 0.4px;
  font: bold 14px var(--primary-font);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.app .video-card .video-card-title .video-date {
  color: #aaaaaa;
  letter-spacing: 0.4px;
  font: normal 12px var(--primary-font);
}
.app .video-card .video-card-title .video-date::first-letter {
  text-transform: capitalize;
}
.app .video-card-load {
  grid-column: span 4;
  display: flex;
  min-width: 100%;
  flex-direction: column;
  margin-bottom: 14px;
}
.app .video-card-load .video-load-cover {
  width: 100%;
  aspect-ratio: 16/9;
  animation: var(--loading);
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .video-card-load .video-load-details {
  gap: 6px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
}
.app .video-card-load .video-load-details .video-load-title {
  width: 300px;
  height: 14px;
  animation: var(--loading);
  border: 1px solid var(--border-color);
}
.app .video-card-load .video-load-details .video-load-date {
  width: 200px;
  height: 11px;
  animation: var(--loading);
  border: 1px solid var(--border-color);
}
.app .photo-card {
  grid-column: span 3;
  min-width: 100%;
  cursor: pointer;
  overflow: hidden;
  aspect-ratio: 1/1;
  position: relative;
  transition: var(--transition);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
  background-color: var(--white-color);
}
.app .photo-card .photo-image {
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  pointer-events: none;
  -o-object-position: center center;
     object-position: center center;
  transition: var(--transition);
}
.app .photo-card .photo-screen {
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  aspect-ratio: 1/1;
  position: absolute;
  transition: var(--transition);
  background-color: transparent;
}
.app .photo-card:hover .photo-image {
  transform: scale(1.1);
}
.app .photo-card-load {
  grid-column: span 3;
  min-width: 100%;
  display: flex;
  aspect-ratio: 1/1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  animation: var(--loading);
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .photo {
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.app .photo .photo-header {
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}
.app .photo .photo-header .container {
  margin: 0 24px;
  width: 100% !important;
  justify-content: space-between;
}
.app .photo .photo-header .container .flex {
  gap: 10px;
  display: flex;
  align-items: center;
}
.app .photo .photo-header .container .flex button {
  color: var(--color-white);
}
.app .photo .content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .photo .content img {
  height: 100%;
  pointer-events: none;
}
.app .header-bar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}
.app .header-bar .icon {
  height: 50px;
  display: flex;
  min-width: 50px;
  align-items: center;
  justify-content: center;
}
.app .header-bar .state {
  height: 50px;
  display: flex;
  min-width: 50px;
  align-items: center;
  justify-content: center;
}
.app .header-bar .title,
.app .header-bar .interpreters,
.app .header-bar .composers {
  width: 100%;
  height: 50px;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  letter-spacing: 0.8px;
  font: bold 16px var(--font-hand);
}
.app .header-bar .composers {
  border-right: none;
}
.app .lyric-card {
  display: flex;
  min-height: 50px;
  align-items: center;
  color: var(--inactive-color);
  transition: var(--transition);
}
.app .lyric-card a {
  width: 100%;
  display: flex;
  min-height: 50px;
  align-items: center;
  text-decoration: none;
}
.app .lyric-card a .icon {
  height: 50px;
  display: flex;
  min-width: 50px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .lyric-card a .icon svg {
  position: absolute;
}
.app .lyric-card a .icon svg:first-child {
  z-index: 2;
}
.app .lyric-card a .icon svg:last-child {
  z-index: 1;
}
.app .lyric-card a .state {
  height: 50px;
  display: flex;
  min-width: 50px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .lyric-card a .state svg {
  color: light-dark(#242424, #aaaaaa);
}
.app .lyric-card a .title,
.app .lyric-card a .interpreters,
.app .lyric-card a .composers {
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 0px 14px;
  white-space: nowrap;
  letter-spacing: 0.8px;
  text-overflow: ellipsis;
  font: normal 14px var(--font-hand);
  color: light-dark(#242424, #aaaaaa);
}
.app .lyric-card:hover {
  background-color: light-dark(rgba(241, 242, 240, 0.9607843137), #202020);
}
.app .product-card {
  grid-column: span 3;
  min-width: 100%;
  display: flex;
  overflow: hidden;
  margin-bottom: 14px;
  flex-direction: column;
}
.app .product-card a {
  color: inherit;
  text-decoration: none;
}
.app .product-card .product-figure {
  display: flex;
  overflow: hidden;
  aspect-ratio: 1/1;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  transition: var(--transition);
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
}
.app .product-card .product-figure .product-state {
  gap: 8px;
  z-index: 4;
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app .product-card .product-figure .product-state .state {
  height: 18px;
  display: flex;
  padding: 0 4px;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: center;
  text-transform: uppercase;
  font: bold 10px var(--font-mono);
  color: var(--white-color);
}
.app .product-card .product-figure .product-state .state:last-child {
  margin-right: 8px;
}
.app .product-card .product-figure .product-state .presale {
  background-color: var(--primary-color);
}
.app .product-card .product-figure .product-state .dto {
  background-color: firebrick;
}
.app .product-card .product-figure .product-state .sold {
  color: var(--black-color);
  background-color: gainsboro;
}
.app .product-card .product-figure .img_def,
.app .product-card .product-figure .img_hov {
  top: 0;
  left: 0;
  width: 100%;
  text-indent: 6px;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  aspect-ratio: 1/1;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  line-height: 18px !important;
  transition: var(--transition);
  font: normal 12px var(--font-hand);
  filter: drop-shadow(4px 4px 6px var(--color-grey-75));
}
.app .product-card .product-figure .img_def {
  z-index: 1;
}
.app .product-card .product-figure .img_hov {
  z-index: 3;
  opacity: 0;
}
.app .product-card .product-figure .back-background {
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  width: 100%;
  aspect-ratio: 1/1;
  visibility: hidden;
  position: absolute;
  transition: var(--transition);
  background-color: var(--white-color);
}
.app .product-card .product-figure:hover .img_hov {
  opacity: 1;
}
.app .product-card .product-figure:hover .back-background {
  opacity: 1;
  visibility: visible;
}
.app .product-card .product-figure .product-screen {
  top: 0px;
  left: 0px;
  z-index: 4;
  width: 100%;
  height: 100%;
  display: flex;
  aspect-ratio: 1/1;
  position: absolute;
  transition: var(--transition);
  background-color: transparent;
}
.app .product-card .product-details {
  gap: 4px;
  display: flex;
  margin-top: 14px;
  align-items: center;
  flex-direction: column;
}
.app .product-card .product-details .product-details-title {
  width: calc(100% - 20px);
  max-height: 50px;
  overflow: hidden;
  display: flex;
  text-align: center;
  justify-content: center;
}
.app .product-card .product-details .product-details-title a {
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font: bold 14px var(--font-display);
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  text-decoration: none;
  letter-spacing: 0.8px;
}
.app .product-card .product-details .product-details-title a:hover {
  text-decoration: underline;
}
.app .product-card .product-details .product-details-price {
  max-width: calc(100% - 60px);
  text-align: center;
  font: normal 12px var(--font-mono);
}
.app .product-card-load {
  grid-column: span 3;
  min-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 14px;
}
.app .product-card-load .product-load-figure {
  width: 100%;
  aspect-ratio: 1/1;
  animation: var(--loading);
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .product-card-load .product-load-details {
  gap: 4px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-direction: column;
}
.app .product-card-load .product-load-details .product-load-title,
.app .product-card-load .product-load-details .product-load-price {
  animation: var(--loading);
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .product-card-load .product-load-details .product-load-title {
  width: 180px;
  height: 14px;
}
.app .product-card-load .product-load-details .product-load-price {
  width: 80px;
  height: 12px;
}
.app .glass {
  opacity: 0;
  width: 100%;
  z-index: 14;
  position: fixed;
  min-height: 100vh;
  visibility: hidden;
  color: transparent;
  transition: var(--transition);
  background-color: var(--glass-color);
}
.app .glass.show {
  opacity: 1;
  visibility: visible;
}
.app .product-load {
  display: flex;
  margin-top: 34px;
  min-height: 625px;
  align-items: center;
  letter-spacing: 0.8px;
  width: var(--wrapper);
  justify-content: center;
  font: bold 20px var(--font-hand);
}
.app .video-modal {
  inset: 0;
  opacity: 0;
  width: 100%;
  z-index: 20;
  display: flex;
  position: fixed;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  background-color: rgba(8, 8, 8, 0.9254901961);
}
.app .video-modal .video-player {
  overflow: hidden !important;
  border-radius: 6px !important;
}
.app .video-modal .video-player-text {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 10px 14px;
  text-align: center;
  pointer-events: none;
  letter-spacing: 0.8px;
  font: normal 12px var(--font-hand);
  border: 1px solid var(--border-color);
  background-color: light-dark(white, black);
  color: light-dark(black, white);
}
.app .video-modal.show {
  opacity: 1;
  visibility: visible;
}
.app .editor {
  padding: 10px;
  overflow: hidden;
  min-height: 400px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--color-black-12);
}
.app .editor .RichEditor-controls {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-color);
}
.app .editor .RichEditor-controls ul {
  gap: 0px;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-start;
}
.app .editor .RichEditor-controls ul li {
  cursor: pointer;
  transition: var(--transition);
  background-color: var(--color-black-12);
}
.app .editor .RichEditor-controls ul li:hover {
  background-color: var(--color-black-18);
}
.app.no-scroll {
  position: fixed !important;
}

/* ---- ---- responsive ---- ---- */
@media screen and (max-width: 1242px) {
  .banner .banner-box {
    width: calc(100% - 40px) !important;
  }
}
@media screen and (max-width: 64em) {
  .header .flex #navMenu {
    display: none !important;
  }
  .footer .footer-newsletter .form {
    width: 100% !important;
  }
  .cookies {
    bottom: 0 !important;
    top: unset !important;
    width: 100% !important;
    border-radius: 12px 12px 0 0 !important;
  }
  .banner .banner-box {
    padding: 20px 0px !important;
    width: calc(100% - 40px) !important;
  }
  .banner .banner-box .banner-box-flex {
    margin: 0 20px !important;
  }
  .banner .banner-box .banner-box-flex .banner-box-data h2 {
    font-size: 34px !important;
  }
  .banner .banner-box .banner-box-flex .banner-box-data h3 {
    font-size: 18px !important;
  }
  .banner .banner-box .banner-box-flex .banner-box-action .primary-button {
    height: 32px !important;
    padding: 0 12px !important;
    font-size: 12px !important;
  }
  .music-card,
  .music-card-load,
  .video-card,
  .video-card-load,
  .photo-card,
  .photo-card-load,
  .product-card,
  .product-card-load {
    grid-column: span 6 !important;
  }
  .music-card .video-card-poster,
  .music-card-load .video-card-poster,
  .video-card .video-card-poster,
  .video-card-load .video-card-poster,
  .photo-card .video-card-poster,
  .photo-card-load .video-card-poster,
  .product-card .video-card-poster,
  .product-card-load .video-card-poster {
    height: 100% !important;
  }
  .music-card .video-card-poster img,
  .music-card-load .video-card-poster img,
  .video-card .video-card-poster img,
  .video-card-load .video-card-poster img,
  .photo-card .video-card-poster img,
  .photo-card-load .video-card-poster img,
  .product-card .video-card-poster img,
  .product-card-load .video-card-poster img {
    -o-object-fit: cover;
       object-fit: cover;
    height: 100% !important;
  }
  .product {
    flex-direction: column !important;
  }
  .product .product-pictures,
  .product .product-details {
    width: 100% !important;
  }
  .product .product-pictures {
    flex-direction: row !important;
  }
  .product .product-pictures .product-pictures-container .product-pictures-image {
    -o-object-fit: cover;
       object-fit: cover;
  }
  .product .product-pictures .product-gallery {
    width: 200px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }
  .product .product-pictures .product-gallery .product-photo {
    grid-column: span 4;
    grid-row: span 3;
  }
  .product .product-details {
    margin-top: -20px;
  }
  .block-content .container {
    width: 100% !important;
  }
}
@media screen and (max-width: 40em) {
  .main .section .container .row-content {
    gap: 4px !important;
  }
  .main .section .container .row-content .aside {
    max-width: 382px;
  }
  .main .section .content h1.title1 {
    font-size: 24px !important;
  }
  .main .section .content p {
    font-size: 11px !important;
  }
  .main .background {
    width: 100%;
    inset: inherit !important;
    top: 112px !important;
  }
  .main .background-i {
    top: 113vh;
    display: flex !important;
  }
  .main .background-ii {
    top: 213vh;
    display: flex !important;
  }
  .footer .footer-newsletter .newsletters-form {
    width: 100% !important;
  }
  .footer .footer-newsletter .newsletters-form #block-6 {
    row-gap: 10px;
    flex-wrap: wrap;
    -moz-column-gap: 0px;
         column-gap: 0px;
  }
  .footer .footer-newsletter .newsletters-form #block-6 .primary-button {
    min-width: 100% !important;
  }
  .footer .footer-copyright {
    flex-direction: column;
  }
  .footer .footer-copyright .copyright {
    width: 100%;
    margin: 14px 0 24px;
    justify-content: center;
  }
  .footer .footer-copyright .policies-links {
    width: 100%;
  }
  .footer .footer-copyright .policies-links .policies {
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
  }
  .footer .footer-copyright .policies-links .policies .policy-item {
    width: 100%;
    text-align: center;
    grid-column: span 2;
  }
  .row-title .select-wrapper input {
    width: 180px !important;
  }
  .row-title .select-wrapper select {
    width: 30px !important;
    padding-left: 34px !important;
  }
  .row-title .select-wrapper #iconDown {
    display: none !important;
  }
  .row-title .select-wrapper #iconFilter {
    display: flex !important;
  }
  .row-content {
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .row-content .column#desktop {
    display: none;
  }
  .row-content .column#mobile {
    display: flex !important;
  }
  .row-content .aside {
    width: 100% !important;
  }
  .row-content .aside .block {
    gap: 8px;
    margin: 10px 0px;
    width: calc(100% - 20px);
    padding: 0px;
    display: flex;
    flex-direction: column;
  }
  .row-content .aside .block figure img {
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    pointer-events: none;
  }
  .row-content .aside .block figure figcaption {
    margin-top: 8px;
    text-align: center;
    text-wrap: balance;
    font: normal 12px var(--font-mono);
  }
  .row-content .aside .block .title {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font: bold 12px var(--font-mono);
  }
  .row-content .aside .block ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  .row-content .aside .block ul li {
    overflow: hidden;
    padding: 4px 0px;
    color: #aaaaaa;
    font: normal 10px var(--font-mono);
  }
  .row-content .aside .block ul li b {
    color: #dedede;
  }
  /* ---- ---- Páginas ---- ---- */
  .lyrics-page .lyrics,
  .lyrics-page .composers {
    width: 100% !important;
  }
  .banner {
    height: calc(100vh - 110px) !important;
  }
  .banner .banner-box {
    flex-direction: column-reverse;
  }
  .banner .banner-box .banner-box-flex {
    overflow: hidden;
    margin: 0 !important;
    width: 100% !important;
  }
  .banner .banner-box .banner-box-flex .banner-box-data h2 {
    font-size: 30px;
  }
  .banner .banner-box .banner-box-flex .banner-box-data h3 {
    font-size: 16px;
  }
  .banner .banner-box .banner-box-flex .banner-box-action .primary-button {
    border-color: var(--inactive-color);
  }
  .banner .banner-box .banner-box-flex .banner-box-image {
    width: calc(100% - 80px) !important;
  }
  .banner .banner-box .banner-box-flex:nth-child(2) {
    padding-bottom: 20px;
  }
  .music-card,
  .music-card-load,
  .video-card,
  .video-card-load,
  .photo-card,
  .photo-card-load,
  .product-card,
  .product-card-load {
    grid-column: span 12 !important;
  }
  .photo .content img {
    width: 100%;
    height: unset !important;
  }
  .product .product-pictures {
    flex-direction: column !important;
  }
  .product .product-pictures .product-gallery {
    width: 100%;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  .product .product-pictures .product-gallery .product-photo {
    grid-column: span 3;
    grid-row: span 4;
  }
  .product .product-pictures .product-gallery .product-photo:active {
    border: 1px solid var(--border-color);
  }
  .no-found {
    min-height: 50vh !important;
  }
  .no-found .title-no-found {
    font-size: 200px !important;
  }
  .no-found .text-no-found {
    font-size: 13px !important;
  }
  .music-page .music-page-image .music-cover-wrapper {
    width: 100% !important;
  }
  .music-page .music-page-image .music-page-grid {
    width: 100% !important;
  }
  .music-page .music-page-data .title {
    font-size: 20px;
  }
  .image-wrapper .image-wrapper-content .wrapped-image {
    width: 100%;
    height: auto !important;
  }
  .video-modal div {
    width: calc(100% - 40px);
  }
  .video-modal .video-player {
    width: 100% !important;
    height: 214px !important;
  }
  .video-modal .video-player-text {
    width: -moz-max-content;
    width: max-content;
  }
}
@media screen and (max-width: 480px) {
  .product-card .product-details .product-details-title {
    font-size: 18px !important;
  }
  .product-card .product-details .product-details-price {
    font-size: 14px !important;
  }
  .music-page {
    flex-direction: column !important;
  }
  .music-page .music-page-image .music-cover {
    width: 100% !important;
  }
  .music-page .music-page-data {
    margin-top: 20px;
  }
}
@media screen and (max-width: 399px) {
  .footer .footer-newsletter {
    display: none !important;
  }
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=app.css.map */