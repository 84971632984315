// ---- ---- main styles ---- ---- //

.app {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    color: var(--text-color);
    transition: var(--transition);
    font-family: var(--primary-font);
    background-color: var(--site-bg-color);

    // ---- ---- Classes ---- ---- //

    .container {
        display: flex;
        margin: 0 20px;
        width: var(--max-width);
    }

    .logo {
        display: flex;
        max-width: 200px;
        align-items: center;
        text-decoration: none;

        svg,
        img {
            height: 26px;
            pointer-events: none;
            transition: var(--transition);
        }
    }

    hr {
        width: 100%;
        height: 1px;
        border: none;
        margin: 8px 0px;
        transition: var(--transition);
        background-color: var(--border-color);
    }

    .grid {
        gap: 8px;
        display: grid;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(12, 1fr);
    }

    table {
        width: 100%;
        overflow: hidden;
        border-spacing: 0px;
        border-collapse: collapse;
        background-color: white;
        border: 1px solid var(--border-color);

        th {
            height: 40px;
            text-align: left;
            padding: 0px 10px;
            font: bold 14px var(--primary-font);
            border-right: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);

            &:last-child {
                border-right: none;
            }
        }

        td {
            height: 40px;
            text-align: left;
            overflow: hidden;
            text-wrap: nowrap;
            padding: 0px 10px;
            text-overflow: ellipsis;
            color: var(--inactive-color);
            font: normal 12px var(--primary-font);
            border-right: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);

            img {
                width: 80px;
                aspect-ratio: 1/1;
            }

            a {
                padding: 0 !important;
                text-decoration: none;
                color: var(--primary-color);
                color: var(--inactive-color);

                &:hover {
                    text-decoration: underline;
                }
            }

            &:last-child {
                border-right: none;
            }
        }

        tbody {
            tr {
                &:last-child {

                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    div.loading {
        user-select: none;
        font: normal 14px var(--font-hand);
    }

    .select-wrapper {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;

        svg {
            right: 12px;
            position: absolute;
            pointer-events: none;
        }

        select,
        input {
            width: 222px;
            height: 34px;
            appearance: none;
            padding-left: 12px;
            letter-spacing: .4px;
            background-color: white;
            transition: var(--transition);
            border: 1px solid var(--border-color);
            font: normal 13px var(--font-display);

            &[type="text"] {
                height: 30px !important;
            }

            &:focus-visible {
                outline: none;
            }
        }

        #iconFilter {
            display: none;
        }
    }

    .preview {
        width: 100%;
        // height: 400px;
        display: flex;
        overflow: hidden;
        align-items: center;
        margin-bottom: 24px;
        justify-content: center;
        border-bottom: 1px dashed var(--border-color);
        // background-color: rosybrown;

        .music-card {
            margin-bottom: 20px;
            min-width: 300px !important;
            // background-color: goldenrod;
        }
    }

    .form {
        gap: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        flex-direction: column;

        .block {
            gap: 8px;
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            .title {
                display: none;
                font: bold 22px var(--font-display);
            }

            label {
                min-width: 80px;
            }

            input,
            select {
                width: 100%;
                height: 34px;
                appearance: none;
                padding: 0px 14px;
                user-select: none;
                background-color: white;
                font: normal 12px var(--font-display);
                border: 1px solid var(--border-color);

                &:focus-visible {
                    outline: none;
                    border-color: var(--primary-color);
                    box-shadow: 0px 0px 8px var(--primary-color);
                }
            }

            .select {
                width: 100%;
                display: flex;
                position: relative;
                align-items: center;

                svg {
                    right: 14px;
                    user-select: none;
                    position: absolute;
                    pointer-events: none;
                }
            }

            .policy {
                user-select: none;
                text-wrap: balance;
                text-align: center;
                font: normal 11px var(--font-display);
                line-height: 2;
            }

            svg,
            .icon {
                right: 14px;
                position: absolute;
            }
        }
    }

    .faqs {
        gap: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .question {
            display: flex;
            align-items: center;
            flex-direction: column;

            b {
                text-wrap: balance;
                text-align: center;
                font: bold 14px var(--font-display);
                line-height: 2;
            }

            p {
                text-wrap: balance;
                text-align: center;
                font: normal 12px var(--font-display);
                line-height: 2;
            }
        }
    }

    // ---- ---- Estructura ---- ---- //

    .top-bar {
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30px;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        background-color: var(--banner-bg-color);

        .container {
            align-items: center;
            justify-content: center;

            .flex-message {
                width: 400px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                .message {
                    opacity: 0;
                    height: 100%;
                    width: 400px;
                    display: flex;
                    cursor: pointer;
                    position: absolute;
                    visibility: hidden;
                    text-wrap: nowrap;
                    align-items: center;
                    letter-spacing: .4px;
                    min-width: max-content;
                    justify-content: center;
                    color: var(--inactive-color);
                    transition: var(--transition);
                    font: normal 11px var(--primary-font);

                    &:hover {
                        color: white;
                    }
                }

                .message.show {
                    opacity: 1;
                    visibility: visible;
                }

                a {
                    text-decoration: none;
                    color: var(--inactive-color);

                    &:hover {
                        color: white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .header {
        left: 0;
        top: 30px;
        z-index: 12;
        width: 100%;
        height: 80px;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        background-color: var(--header-bg-color);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);

        .container {
            height: 34px;
            align-items: center;
            justify-content: space-between;

            .flex {
                gap: 14px;
                width: 100%;
                display: flex;
                align-items: center;

                .nav-menu {
                    height: 80px;
                    display: flex;
                    align-items: center;

                    .nav-list {
                        gap: 14px;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        display: flex;
                        list-style: none;
                        align-items: center;

                        .nav-item {
                            height: 80px;
                            display: flex;
                            position: relative;
                            align-items: center;
                            justify-content: center;

                            .nav-link {
                                height: 100%;
                                display: flex;
                                padding: 0 6px;
                                user-select: none;
                                align-items: center;
                                letter-spacing: .4px;
                                text-decoration: none;
                                min-width: max-content;
                                text-transform: capitalize;
                                transition: var(--transition);
                                font: normal 16px var(--primary-font);
                                color: light-dark(var(--color-black-20), var(--color-white-ff));

                                &:hover {
                                    text-decoration: underline;
                                }

                                svg {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                hr {
                    width: 1px;
                    height: 80px;
                    border: none;
                    transition: var(--transition);
                    background-color: var(--border-color);
                }

                .icon-button {
                    color: light-dark(var(--color-black-20), var(--color-white-ff));
                }

                &:nth-child(1) {
                    justify-content: flex-start;

                    .icon-button {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    justify-content: flex-end;
                }

                &:last-child {
                    gap: 20px;
                    justify-content: flex-end;
                }
            }
        }
    }

    .menu {
        top: 0px;
        z-index: 16;
        width: 300px;
        right: -300px;
        height: 100vh;
        display: flex;
        position: fixed;
        flex-direction: column;
        box-shadow: var(--elevated);
        transition: var(--transition);
        justify-content: space-between;
        background-color: var(--site-bg-color);
        border-left: 1px solid var(--border-color);

        .menu-header {
            height: 80px;
            display: flex;
            text-indent: 50px;
            user-select: none;
            position: relative;
            align-items: center;
            transition: var(--transition);
            justify-content: space-between;
            border-top: 1px solid transparent;
            border-bottom: 1px solid var(--border-color);

            .menu-title {
                letter-spacing: .4px;
                font: bold 16px var(--primary-font);
                color: light-dark(var(--color-black-20), var(--color-white-ff));
            }

            .icon-button {
                right: 50px;
                position: absolute;
            }
        }

        .menu-navigator {
            width: 100%;
            height: calc(100vh - 81px);
            display: flex;
            overflow-y: auto;
            align-items: center;
            flex-direction: column;

            .nav-menu {
                width: 100%;

                .nav-list {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;

                    .nav-item {
                        gap: 10px;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        transition: var(--transition);
                        justify-content: space-between;
                        border-bottom: 1px solid var(--border-color);

                        .nav-link {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            position: relative;
                            align-items: center;
                            letter-spacing: .4px;
                            justify-content: flex-start;
                            font: normal 14px var(--font-display);
                            color: light-dark(#757575, #999999);
                            transition: var(--transition);
                            text-decoration: none;
                            text-indent: 50px;
                            user-select: none;
                            cursor: pointer;

                            svg {
                                right: 50px;
                                position: absolute;
                            }

                            &:active {
                                text-decoration: underline;
                            }
                        }

                        &:hover {
                            background-color: #f5f5f5;

                            .nav-link {
                                color: var(--text-color);
                            }
                        }

                        &.hidden {
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }

        &.show {
            right: 0;
        }
    }

    .language {
        top: -180px;
        opacity: 0;
        z-index: 16;
        width: 300px;
        display: flex;
        padding: 18px;
        position: fixed;
        visibility: hidden;
        border-radius: 12px;
        flex-direction: column;
        box-shadow: var(--elevated);
        transition: var(--transition);
        background-color: light-dark(var(--color-white-ff), var(--color-black-20));

        .language-select {
            display: flex;
            position: relative;
            align-items: center;

            select {
                width: 100%;
                height: 34px;
                appearance: none;
                text-indent: 10px;
                border-radius: 4px;
                letter-spacing: .4px;
                transition: var(--transition);
                font: normal 12px var(--primary-font);
                border: 1px solid var(--border-color);
                background-color: light-dark(var(--color-white-fa), var(--color-black-18));
            }

            button {
                right: 1px;
                height: 30px;
                position: absolute;
                pointer-events: none;
                transition: var(--transition);
                color: light-dark(var(--color-black-18), var(--color-white-fa));
            }
        }

        &.show {
            top: 180px;
            opacity: 1;
            visibility: visible;
        }
    }

    .main {
        width: 100%;
        display: flex;
        overflow: hidden;
        position: relative;
        align-items: center;
        flex-direction: column;
        padding-top: calc(30px + 80px + 2px);
        padding-bottom: 80px;

        .section,
        section {
            z-index: 5;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .container {
                flex-direction: column;
            }

            &.m-top {
                margin-top: -40px;
            }

        }

        .background {
            inset: 0;
            top: calc(30px + 80px + 2px);
            z-index: 1;
            min-height: 100vh;
            position: absolute;
            filter: blur(50px);
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            transition: var(--transition);

            &.mask {
                mask-image: var(--mask);
            }

            &.show {
                filter: blur(0) !important;
            }
        }

        .background-i {
            z-index: 3;
            width: 100%;
            display: none;
            min-height: 100vh;
            filter: blur(50px);
            position: absolute;
            transform: scaleX(-1);
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            transition: var(--transition);

            &.show {
                filter: blur(0) !important;
            }
        }

        .background-ii {
            z-index: 3;
            width: 100%;
            display: none;
            min-height: 100vh;
            filter: blur(50px);
            position: absolute;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            transition: var(--transition);

            &.show {
                filter: blur(0) !important;
            }
        }

        .background-glass {
            inset: 0;
            z-index: 4;
            position: absolute;
            background-color: var(--glass-color);
        }
    }

    .footer {
        z-index: 4;
        width: 100%;
        display: flex;
        padding: 32px 0;
        justify-content: center;
        transition: var(--transition);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        background-color: var(--footer-bg-color);

        .container {
            flex-direction: column;

            .footer-newsletter {
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .form {
                    gap: 8px;
                    width: 40%;
                    display: flex;
                    align-items: center;
                    padding-bottom: 32px;
                    flex-direction: column;

                    .block {
                        gap: 8px;
                        width: 100%;
                        display: flex;
                        position: relative;
                        align-items: center;
                        flex-direction: row;
                        justify-content: center;
                    }

                    .title {
                        margin-bottom: 14px;
                        display: flex !important;
                        font: normal 20px var(--font-display);
                    }

                    .policy {
                        color: #aaaaaa;
                    }
                }
            }

            .footer-socials {
                display: flex;
                align-items: center;
                justify-content: center;

                .social-icons {
                    gap: 15px;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    align-items: center;
                    padding-bottom: 32px;
                    justify-content: center;

                    .social {
                        width: 28px;
                        height: 28px;
                        display: flex;
                        position: relative;
                        align-items: center;
                        justify-content: center;

                        a {
                            inset: 0;
                            display: flex;
                            position: absolute;
                            align-items: center;
                            justify-content: center;
                        }

                        svg {
                            font-size: 22px;
                        }
                    }
                }
            }

            .footer-copyright {
                display: flex;
                padding: 10px 0;
                align-items: center;
                justify-content: space-between;

                .copyright {
                    display: flex;
                    color: #757575;
                    align-items: center;
                    font: normal 11px var(--font-display);

                    svg {
                        font-size: 9px;
                        margin-right: 4px;
                    }
                }

                .policies-links {
                    display: flex;

                    .policies {
                        gap: 14px;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        list-style: none;

                        .policy-item {
                            min-width: max-content;
                        }

                        .policy-link {
                            cursor: pointer;
                            color: #757575;
                            letter-spacing: .2px;
                            min-width: max-content;
                            text-decoration: underline;
                            transition: var(--transition);
                            font: normal 11px var(--font-display);

                            &:hover {
                                color: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .cookies {
        top: 180px;
        opacity: 0;
        z-index: 20;
        width: 600px;
        display: flex;
        margin: 0 32px;
        position: fixed;
        overflow: hidden;
        padding: 18px 0px;
        visibility: hidden;
        border-radius: 18px;
        justify-content: center;
        transition: var(--transition);
        border: 1px solid var(--border-color);
        background-color: var(--white-color);

        .container {
            flex-direction: column;

            hr {
                margin: 0px;
                border: none;
                background: none;
                border-top: 1px dashed var(--border-color);
            }

            .cookies-header {
                display: flex;
                align-items: center;
                margin-bottom: 14px;
                flex-direction: row;
                justify-content: space-between;

                .cookies-flex {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .cookies-title {
                        display: flex;
                        user-select: none;
                        align-items: center;
                        justify-content: center;
                        font: bold 14px var(--font-display);
                    }

                    .cookies-button {
                        width: 34px;
                        display: flex;
                        cursor: pointer;
                        margin-left: 20px;
                        align-items: center;
                        justify-content: center;
                    }

                    &:last-child {
                        justify-content: flex-end;
                    }
                }
            }

            .cookies-content {
                gap: 14px;
                display: flex;
                padding: 14px 0px;
                flex-direction: column;

                .cookies-box {
                    display: flex;

                    .cookies-box-data {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .cookies-box-title {
                            width: 100%;
                            font: bold 12px var(--font-display);
                            line-height: 2.5;
                        }

                        .cookies-box-info {
                            font: normal 12px var(--font-display);
                            line-height: 1.5;
                        }
                    }

                    .cookies-box-control {
                        display: flex;
                        min-width: 40px;
                        align-items: flex-end;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
            }

            .cookies-action {
                gap: 8px;
                display: flex;
                margin-top: 14px;
                align-items: center;
                justify-content: space-between;

                .button {
                    width: 100% !important;

                    &.show {
                        border-color: var(--primary-color);
                        background-color: var(--primary-color);
                    }
                }

            }
        }
    }

    .cookies.show {
        opacity: 1;
        visibility: visible;
    }

    /* ---- ---- Páginas ---- ---- */

    .lyrics-page {
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        .lyrics {
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;

            h1 {
                width: 100%;
                margin: 0 0 30px;
                text-align: center;
                text-wrap: balance;
                font: bold 30px var(--primary-font);
            }

            .lyric {
                width: 100%;

                p {
                    text-align: center;
                    font: normal 14px / 2 var(--primary-font);

                    span {
                        color: #757575;
                        font-weight: bold;
                    }
                }
            }
        }

        .composers {
            gap: 10px;
            width: 50%;
            display: flex;
            padding: 20px 0px;
            align-items: center;
            flex-direction: column;
            border-top: 1px solid var(--border-color);

            h3,
            p {
                margin: 0;
            }

            h3 {
                font: bold 18px / 2 var(--primary-font);
            }

            p {
                font: normal 14px / 2 var(--primary-font);
            }
        }

        .row-action {
            display: none;
        }
    }

    /* ---- ---- Componentes ---- ---- */

    .banner {
        margin-top: -30px;
        width: var(--wrapper) !important;
        max-width: 100%;
        height: calc(100vh - 80px - 30px);
        position: relative;
        overflow: hidden;
        border-radius: 0 0 12px 12px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        background-color: #cdcdcd;

        .slides {
            display: flex;
            transition: transform 0.5s ease-in-out;
            width: 300%;

            .slide {
                flex: 1;
                height: 770px;
                display: flex;
                min-width: 100%;
                justify-content: center;
                align-items: center;
                background-size: cover;
                background-position: center;
                font-size: 1.5rem;
                font-weight: bold;
                color: white;
                text-align: center;

                .content {
                    background: rgba(0, 0, 0, 0.5);
                    padding: 20px;
                    border-radius: 16px;
                    border: 1px solid rgba(0, 0, 0, 0.7);

                    .cover {
                        aspect-ratio: 1;
                        user-select: none;
                        object-fit: cover;
                        border-radius: 12px;
                        pointer-events: none;
                        object-position: center;
                        border: 1px solid #242424;
                    }

                    .title {
                        margin: 20px 0;
                        user-select: none;
                        pointer-events: none;
                        font: bold 2rem var(--font-display);
                    }

                    .subtitle {
                        user-select: none;
                        margin-bottom: 20px;
                        pointer-events: none;
                        font: normal 1.2rem var(--font-display);
                    }

                    .actions {
                        gap: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        button {
                            background-color: rgba(255, 255, 255, 0.8);
                            color: black;
                            border: none;
                            padding: 10px 20px;
                            // margin: 0 10px;
                            cursor: pointer;
                            border-radius: 5px;
                            user-select: none;
                            font: normal 1rem var(--font-display);
                            transition: background-color 0.3s ease;

                            &:hover {
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }

        .controls {
            position: absolute;
            top: 50%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            transform: translateY(-50%);

            button {
                opacity: 0;
                width: 36px;
                height: 36px;
                margin: 0 16px;
                visibility: hidden;
                background-color: rgba(0, 0, 0, 0.5);
                border: none;
                color: white;
                cursor: pointer;
                user-select: none;
                border-radius: 8px;
                transition: var(--transition);
                border: 1px solid rgba(0, 0, 0, 0.7);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.7);
                }
            }
        }

        .stop-btn {
            opacity: 0;
            width: 20px;
            height: 20px;
            visibility: hidden;
            position: absolute;
            bottom: 16px;
            right: 16px;
            padding: 0;
            border: none;
            cursor: pointer;
            background: none;
            border-radius: 8px;
            color: var(--white-color);
            transition: var(--transition);
        }

        &:hover {
            .controls {
                button {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .stop-btn {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .block-title {
        gap: 18px;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 40px 0px 30px;
        flex-direction: column;
        justify-content: center;

        h1 {
            margin: 0;
            font: bold 22px var(--font-display);
        }
    }

    .block-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .container {
            gap: 10px;
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    .row-title,
    .row-action {
        display: flex;
        padding: 40px 0px;
        align-items: center;
        flex-direction: row;
    }

    .row-title {
        justify-content: space-between;

        h2 {
            width: 100%;
            margin: 0px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font: bold 28px var(--primary-font);
            // background-color: fuchsia;
        }

        .flex-action {
            gap: 8px;
            width: 100%;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .row-action {
        justify-content: flex-end;
    }

    .article {
        width: 100%;
        display: flex;
        margin-top: 40px;
        align-items: center;
        flex-direction: column;

        h1 {
            width: 100%;
            text-align: center;
            margin: 0px 0px 40px;
            font: normal 30px var(--font-display);
        }

        p {
            width: 100%;
            text-align: justify;
            font: normal 12px var(--font-display);
            color: var(--text-color);
            line-height: 2;
        }
    }

    // .banner {
    //     width: 100%;
    //     height: 815px;
    //     display: flex;
    //     overflow: hidden;
    //     background-size: cover;
    //     flex-direction: column;
    //     justify-content: center;
    //     background-position: center;
    //     align-items: center !important;

    //     .banner-box {
    //         margin: 0 20px;
    //         width: var(--max-width);
    //         display: flex;
    //         overflow: hidden;
    //         padding: 40px 0px;
    //         align-items: center;
    //         justify-content: center;
    //         backdrop-filter: blur(20px);
    //         background-color: transparent;
    //         transition: var(--transition);
    //         border: 1px solid var(--border-color);

    //         .banner-box-flex {
    //             width: 100%;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;

    //             h2,
    //             h3 {
    //                 margin: 0;
    //                 color: white;
    //                 cursor: default;
    //                 font-family: var(--font-display);
    //             }

    //             h2 {
    //                 font-size: 50px;
    //                 font-weight: bold;
    //                 text-shadow: 1px 1px 2px black;
    //             }

    //             h3 {
    //                 font-size: 20px;
    //                 font-weight: normal;
    //                 text-shadow: 1px 1px 2px var(--color-white);
    //             }

    //             .banner-box-image {
    //                 display: flex;
    //                 overflow: hidden;
    //                 align-items: center;
    //                 justify-content: center;
    //                 text-decoration: none;

    //                 img {
    //                     width: 400px;
    //                     display: flex;
    //                     overflow: hidden;
    //                     object-fit: cover;
    //                     pointer-events: none;
    //                     object-position: center;
    //                     filter: brightness(0.9);
    //                     aspect-ratio: 1/1;
    //                 }

    //                 .box {
    //                     width: 400px;
    //                     aspect-ratio: 1;
    //                     display: flex;
    //                     align-items: center;
    //                     justify-content: center;
    //                     font: bold 20px var(--primary-font);
    //                     border: 1px solid var(--border-color);
    //                     color: white;
    //                 }
    //             }

    //             .banner-box-data {
    //                 width: 100%;
    //                 display: flex;
    //                 align-items: center;
    //                 flex-direction: column;
    //                 margin-bottom: 30px;

    //                 h2 {
    //                     margin-bottom: 20px;
    //                 }
    //             }

    //             .banner-box-action {
    //                 width: 100%;
    //                 display: flex;
    //                 margin: 8px 0;
    //                 align-items: center;
    //                 justify-content: center;

    //                 .primary-button {
    //                     animation: var(--shake);
    //                 }
    //             }

    //             &:nth-child(1) {
    //                 margin-left: 40px;
    //                 flex-direction: column;
    //             }

    //             &:nth-child(2) {
    //                 margin-right: 40px;
    //                 flex-direction: row;
    //             }
    //         }
    //     }
    // }

    .music-card {
        grid-column: span 3;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;

        .music-card-link {
            text-decoration: none;
        }

        .music-card-cover {
            width: 100%;
            display: flex;
            color: white;
            overflow: hidden;
            aspect-ratio: 1/1;
            position: relative;
            align-items: center;
            justify-content: center;
            font: bold 18px var(--primary-font);
            border: 1px solid var(--border-color);

            .music-cover {
                width: 100%;
                height: 100%;
                display: flex;
                object-fit: cover;
                aspect-ratio: 1/1;
                align-items: center;
                pointer-events: none;
                object-position: center;
                justify-content: center;
                transition: var(--transition);
                // background-color: light-dark(var(--color-white-ff), var(--color-black-20));
            }

            .music-card-songs {
                gap: 4px;
                right: 8px;
                bottom: 8px;
                height: 20px;
                display: flex;
                cursor: pointer;
                padding: 1px 6px;
                position: absolute;
                align-items: center;
                justify-content: center;
                background-color: #2b2b2bab;

                .music-card-songs-icon {
                    width: 12px;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 16px;
                        user-select: none;
                        pointer-events: none;
                    }
                }

                .music-card-songs-text {
                    letter-spacing: .4px;
                    color: var(--color-white-ff);
                    font: normal 12px var(--primary-font);
                }
            }
        }

        .music-card-caption {
            gap: 6px;
            width: 100%;
            display: flex;
            margin-top: 14px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .music-card-title {
                width: 100%;
                line-clamp: 2;
                max-height: 40px;
                overflow: hidden;
                display: -webkit-box;
                letter-spacing: .4px;
                -webkit-line-clamp: 2;
                font: bold 14px var(--primary-font);
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .music-card-type {
                color: #aaaaaa;
                letter-spacing: .4px;
                font: normal 12px var(--primary-font);
            }
        }

        .music-screen {
            top: 0px;
            left: 0px;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            aspect-ratio: 1/1;
            position: absolute;
            transition: var(--transition);
            background-color: transparent;
        }
    }

    .music-card-load {
        grid-column: span 3;
        display: flex;
        min-width: 100%;
        flex-direction: column;
        margin-bottom: 14px;

        .music-load-cover {
            width: 100%;
            aspect-ratio: 1/1;
            animation: var(--loading);
            transition: var(--transition);
            border: 1px solid var(--border-color);
        }

        .music-load-details {
            gap: 8px;
            display: flex;
            margin-top: 12px;
            flex-direction: column;
            align-items: flex-start;

            .music-load-title {
                width: 140px;
                height: 14px;
                display: flex;
                animation: var(--loading);
                border: 1px solid var(--border-color);
            }

            .music-load-type {
                width: 200px;
                height: 12px;
                display: flex;
                animation: var(--loading);
                border: 1px solid var(--border-color);
            }
        }
    }

    .video-card {
        display: flex;
        min-width: 100%;
        cursor: pointer;
        grid-column: span 4;
        flex-direction: column;
        transition: var(--transition);
        margin-bottom: 14px;

        a {
            color: inherit;
            text-decoration: none;
        }

        .video-card-cover {
            width: 100%;
            display: flex;
            overflow: hidden;
            position: relative;
            aspect-ratio: 16/9;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--border-color);
            background-color: light-dark(var(--color-white-ff), var(--color-black-20));

            .video-card-link {
                width: 100%;
                height: 100%;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
            }

            .video-cover-back {
                z-index: 2;
                width: 100%;
                opacity: .7;
                display: flex;
                user-select: none;
                object-fit: cover;
                filter: blur(30px);
                position: absolute;
                align-items: center;
                pointer-events: none;
                object-position: center;
                justify-content: center;
            }

            .video-cover {
                width: auto;
                z-index: 3;
                height: 100%;
                display: flex;
                max-width: 100%;
                object-fit: cover;
                user-select: none;
                position: absolute;
                pointer-events: none;
                object-position: center;
                font: normal 12px var(--primary-font);
            }

            .video-order {
                top: 8px;
                right: 8px;
                z-index: 4;
                display: flex;
                padding: 2px 8px;
                position: absolute;
                align-items: center;
                color: var(--color-white-ff);
                font: normal 12px var(--primary-font);
                box-shadow: 2px 2px 8px #3535354d;
            }

            .background {
                inset: 0 !important;
                z-index: 1;
                filter: none;
                display: flex;
                min-height: auto;
                user-select: none;
                position: absolute;
                align-items: center;
                pointer-events: none;
                justify-content: center;

                .title {
                    color: white;
                    user-select: none;
                    font: bold 18px var(--primary-font);
                }
            }
        }

        .video-card-title {
            gap: 6px;
            display: flex;
            padding-top: 14px;
            position: relative;
            flex-direction: column;
            align-items: flex-start;

            .video-title {
                width: 100%;
                line-clamp: 2;
                max-height: 40px;
                overflow: hidden;
                display: -webkit-box;
                letter-spacing: .4px;
                font: bold 14px var(--primary-font);
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .video-date {
                color: #aaaaaa;
                letter-spacing: .4px;
                font: normal 12px var(--primary-font);

                &::first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }

    .video-card-load {
        grid-column: span 4;
        display: flex;
        min-width: 100%;
        flex-direction: column;
        margin-bottom: 14px;

        .video-load-cover {
            width: 100%;
            aspect-ratio: 16/9;
            animation: var(--loading);
            transition: var(--transition);
            border: 1px solid var(--border-color);
        }

        .video-load-details {
            gap: 6px;
            display: flex;
            margin-top: 10px;
            flex-direction: column;
            align-items: flex-start;

            .video-load-title {
                width: 300px;
                height: 14px;
                animation: var(--loading);
                border: 1px solid var(--border-color);
            }

            .video-load-date {
                width: 200px;
                height: 11px;
                animation: var(--loading);
                border: 1px solid var(--border-color);
            }
        }
    }

    .photo-card {
        grid-column: span 3;
        min-width: 100%;
        cursor: pointer;
        overflow: hidden;
        aspect-ratio: 1/1;
        position: relative;
        transition: var(--transition);
        // border: 1px solid var(--border-color);
        background-color: light-dark(var(--color-white-ff), var(--color-black-20));
        background-color: var(--white-color);

        .photo-image {
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            aspect-ratio: 1/1;
            object-fit: cover;
            position: absolute;
            pointer-events: none;
            object-position: center center;
            transition: var(--transition);
        }

        .photo-screen {
            top: 0px;
            left: 0px;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            aspect-ratio: 1/1;
            position: absolute;
            transition: var(--transition);
            background-color: transparent;
        }

        &:hover {

            .photo-image {
                transform: scale(1.1);
            }
        }
    }

    .photo-card-load {
        grid-column: span 3;
        min-width: 100%;
        display: flex;
        aspect-ratio: 1/1;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        animation: var(--loading);
        transition: var(--transition);
        border: 1px solid var(--border-color);
    }

    .photo {
        top: 0;
        z-index: 20;
        width: 100%;
        height: 100vh;
        display: flex;
        position: fixed;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: light-dark(var(--color-white-fa), var(--color-black-18));

        .photo-header {
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            display: flex;
            position: fixed;
            align-items: center;
            justify-content: center;

            .container {
                margin: 0 24px;
                width: 100% !important;
                justify-content: space-between;

                .flex {
                    gap: 10px;
                    display: flex;
                    align-items: center;

                    button {
                        color: var(--color-white);
                    }
                }
            }
        }

        .content {
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 100%;
                pointer-events: none;
            }
        }
    }

    .header-bar {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--border-color);

        .icon {
            height: 50px;
            display: flex;
            min-width: 50px;
            align-items: center;
            justify-content: center;
        }

        .state {
            height: 50px;
            display: flex;
            min-width: 50px;
            align-items: center;
            justify-content: center;
        }

        .title,
        .interpreters,
        .composers {
            width: 100%;
            height: 50px;
            display: flex;
            user-select: none;
            // padding: 0px 14px;
            align-items: center;
            letter-spacing: .8px;
            font: bold 16px var(--font-hand);
        }

        .composers {
            border-right: none;
        }
    }

    .lyric-card {
        display: flex;
        min-height: 50px;
        // border-radius: 4px;
        align-items: center;
        color: var(--inactive-color);
        transition: var(--transition);

        a {
            width: 100%;
            display: flex;
            min-height: 50px;
            align-items: center;
            text-decoration: none;

            .icon {
                height: 50px;
                display: flex;
                min-width: 50px;
                position: relative;
                align-items: center;
                justify-content: center;

                svg {
                    position: absolute;

                    &:first-child {
                        z-index: 2;
                    }

                    &:last-child {
                        z-index: 1;
                    }
                }
            }

            .state {
                height: 50px;
                display: flex;
                min-width: 50px;
                position: relative;
                align-items: center;
                justify-content: center;

                svg {
                    color: light-dark(#242424, #aaaaaa);
                }
            }

            .title,
            .interpreters,
            .composers {
                width: 100%;
                display: block;
                overflow: hidden;
                padding: 0px 14px;
                white-space: nowrap;
                letter-spacing: .8px;
                text-overflow: ellipsis;
                font: normal 14px var(--font-hand);
                color: light-dark(#242424, #aaaaaa);
            }
        }

        &:hover {
            background-color: light-dark(#f1f2f0f5, #202020);
        }
    }

    .product-card {
        grid-column: span 3;
        min-width: 100%;
        display: flex;
        overflow: hidden;
        margin-bottom: 14px;
        flex-direction: column;

        a {
            color: inherit;
            text-decoration: none;
        }

        .product-figure {
            display: flex;
            overflow: hidden;
            aspect-ratio: 1/1;
            position: relative;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            transition: var(--transition);
            background-color: var(--white-color);
            border: 1px solid var(--border-color);

            .product-state {
                gap: 8px;
                z-index: 4;
                width: 100%;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .state {
                    height: 18px;
                    display: flex;
                    padding: 0 4px;
                    align-items: center;
                    letter-spacing: .4px;
                    justify-content: center;
                    text-transform: uppercase;
                    font: bold 10px var(--font-mono);
                    color: var(--white-color);

                    &:last-child {
                        margin-right: 8px;
                    }
                }

                .presale {
                    background-color: var(--primary-color);
                }

                .dto {
                    background-color: firebrick;
                }

                .sold {
                    color: var(--black-color);
                    background-color: gainsboro;
                }
            }

            .img_def,
            .img_hov {
                top: 0;
                left: 0;
                width: 100%;
                text-indent: 6px;
                object-fit: cover;
                position: absolute;
                aspect-ratio: 1/1;
                pointer-events: none;
                object-position: center;
                line-height: 18px !important;
                transition: var(--transition);
                font: normal 12px var(--font-hand);
                filter: drop-shadow(4px 4px 6px var(--color-grey-75));
            }

            .img_def {
                z-index: 1;
            }

            .img_hov {
                z-index: 3;
                opacity: 0;
            }

            .back-background {
                top: 0;
                left: 0;
                z-index: 2;
                opacity: 0;
                width: 100%;
                aspect-ratio: 1/1;
                visibility: hidden;
                position: absolute;
                transition: var(--transition);
                background-color: var(--white-color);
            }

            &:hover {

                .img_hov {
                    opacity: 1;
                }

                .back-background {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .product-screen {
                top: 0px;
                left: 0px;
                z-index: 4;
                width: 100%;
                height: 100%;
                display: flex;
                aspect-ratio: 1/1;
                position: absolute;
                transition: var(--transition);
                background-color: transparent;
            }
        }

        .product-details {
            gap: 4px;
            display: flex;
            margin-top: 14px;
            align-items: center;
            flex-direction: column;

            .product-details-title {
                width: calc(100% - 20px);
                max-height: 50px;
                overflow: hidden;
                display: flex;
                text-align: center;
                justify-content: center;

                a {
                    line-clamp: 2;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    font: bold 14px var(--font-display);
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    white-space: normal;
                    text-decoration: none;
                    letter-spacing: .8px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .product-details-price {
                max-width: calc(100% - 60px);
                text-align: center;
                font: normal 12px var(--font-mono);
            }
        }
    }

    .product-card-load {
        grid-column: span 3;
        min-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 14px;

        .product-load-figure {
            width: 100%;
            aspect-ratio: 1/1;
            animation: var(--loading);
            transition: var(--transition);
            border: 1px solid var(--border-color);
        }

        .product-load-details {
            gap: 4px;
            width: 100%;
            display: flex;
            margin-top: 10px;
            align-items: center;
            flex-direction: column;

            .product-load-title,
            .product-load-price {
                animation: var(--loading);
                transition: var(--transition);
                border: 1px solid var(--border-color);
            }

            .product-load-title {
                width: 180px;
                height: 14px;
            }

            .product-load-price {
                width: 80px;
                height: 12px;
            }
        }
    }

    .glass {
        opacity: 0;
        width: 100%;
        z-index: 14;
        position: fixed;
        min-height: 100vh;
        visibility: hidden;
        color: transparent;
        transition: var(--transition);
        background-color: var(--glass-color);
    }

    .glass.show {
        opacity: 1;
        visibility: visible;
    }

    .product-load {
        display: flex;
        margin-top: 34px;
        min-height: 625px;
        align-items: center;
        letter-spacing: .8px;
        width: var(--wrapper);
        justify-content: center;
        font: bold 20px var(--font-hand);
    }

    .video-modal {
        inset: 0;
        opacity: 0;
        width: 100%;
        z-index: 20;
        display: flex;
        position: fixed;
        visibility: hidden;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        background-color: #080808ec;

        .video-player {
            overflow: hidden !important;
            border-radius: 6px !important;
        }

        .video-player-text {
            user-select: none;
            padding: 10px 14px;
            // border-radius: 6px;
            text-align: center;
            pointer-events: none;
            letter-spacing: .8px;
            font: normal 12px var(--font-hand);
            border: 1px solid var(--border-color);
            background-color: light-dark(white, black);
            color: light-dark(black, white);
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .editor {
        padding: 10px;
        overflow: hidden;
        min-height: 400px;
        border-radius: 8px;
        border: 1px solid var(--border-color);
        background-color: var(--color-black-12);

        .RichEditor-controls {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            justify-content: flex-start;
            border-bottom: 1px solid var(--border-color);

            ul {
                gap: 0px;
                margin: 0;
                padding: 0;
                width: 100%;
                display: flex;
                list-style: none;
                align-items: center;
                justify-content: flex-start;
                // background-color: var(--border-color);

                li {
                    cursor: pointer;
                    // padding: 0px 4px;
                    transition: var(--transition);
                    background-color: var(--color-black-12);
                    // border-right: 1px solid var(--border-color);

                    &:hover {
                        background-color: var(--color-black-18);
                    }

                    // &:first-child {
                    //     border-left: 1px solid var(--border-color);
                    // }
                }
            }
        }
    }

    &.no-scroll {
        position: fixed !important;
    }
}

/* ---- ---- responsive ---- ---- */

@media screen and (max-width: 1242px) {
    .banner {
        .banner-box {
            width: calc(100% - 40px) !important;
        }
    }
}

@media screen and (max-width: 64em) {

    // ---- ---- components | layout ---- ---- //

    .header {

        .flex {

            #navMenu {
                display: none !important;
            }
        }
    }

    .footer {
        .footer-newsletter {
            .form {
                width: 100% !important;
            }
        }
    }

    .cookies {
        bottom: 0 !important;
        top: unset !important;
        width: 100% !important;
        border-radius: 12px 12px 0 0 !important;
    }

    // ---- ---- components | common ---- ---- //

    .banner {

        .banner-box {
            padding: 20px 0px !important;
            width: calc(100% - 40px) !important;

            .banner-box-flex {
                margin: 0 20px !important;

                .banner-box-data {

                    h2 {
                        font-size: 34px !important;
                    }

                    h3 {
                        font-size: 18px !important;
                    }
                }

                .banner-box-action {

                    .primary-button {
                        height: 32px !important;
                        padding: 0 12px !important;
                        font-size: 12px !important;
                    }
                }
            }
        }
    }

    .music-card,
    .music-card-load,
    .video-card,
    .video-card-load,
    .photo-card,
    .photo-card-load,
    .product-card,
    .product-card-load {
        grid-column: span 6 !important;

        .video-card-poster {
            height: 100% !important;

            img {
                object-fit: cover;
                height: 100% !important;
            }
        }
    }

    .product {
        flex-direction: column !important;

        .product-pictures,
        .product-details {
            width: 100% !important;
        }

        .product-pictures {
            flex-direction: row !important;

            .product-pictures-container {

                .product-pictures-image {
                    object-fit: cover;
                }
            }

            .product-gallery {
                width: 200px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(12, 1fr);

                .product-photo {
                    grid-column: span 4;
                    grid-row: span 3;
                }
            }
        }

        .product-details {
            margin-top: -20px;
        }
    }

    .block-content {
        .container {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 40em) {

    // ---- ---- components | layout ---- ---- //

    .main {
        .section {

            .container {
                .row-content {
                    gap: 4px !important;

                    .aside {
                        max-width: 382px;
                    }
                }
            }

            .content {


                h1.title1 {
                    font-size: 24px !important;
                }

                p {
                    font-size: 11px !important;
                }
            }
        }

        .background {
            width: 100%;
            inset: inherit !important;
            top: calc(30px + 80px + 2px) !important;
        }

        .background-i {
            top: 113vh;
            display: flex !important;
        }

        .background-ii {
            top: 213vh;
            display: flex !important;
        }
    }

    .footer {

        .footer-newsletter {

            .newsletters-form {
                width: 100% !important;

                #block-6 {
                    row-gap: 10px;
                    flex-wrap: wrap;
                    column-gap: 0px;

                    .primary-button {
                        min-width: 100% !important;
                    }
                }
            }
        }

        .footer-copyright {
            flex-direction: column;

            .copyright {
                width: 100%;
                margin: 14px 0 24px;
                justify-content: center;
            }

            .policies-links {
                width: 100%;

                .policies {
                    width: 100%;
                    display: grid !important;
                    grid-template-columns: repeat(4, 1fr);

                    .policy-item {
                        width: 100%;
                        text-align: center;
                        grid-column: span 2;
                    }
                }
            }
        }
    }

    .row-title {

        .select-wrapper {
            input {
                width: 180px !important;
            }

            select {
                width: 30px !important;
                padding-left: 34px !important;
            }

            #iconDown {
                display: none !important;
            }

            #iconFilter {
                display: flex !important;
            }
        }
    }

    .row-content {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .column {

            &#desktop {
                display: none;
            }

            &#mobile {
                display: flex !important;
            }
        }

        .aside {
            width: 100% !important;

            .block {
                gap: 8px;
                margin: 10px 0px;
                width: calc(100% - 20px);
                padding: 0px;
                display: flex;
                flex-direction: column;

                figure {
                    img {
                        width: 100%;
                        user-select: none;
                        pointer-events: none;
                    }

                    figcaption {
                        margin-top: 8px;
                        text-align: center;
                        text-wrap: balance;
                        font: normal 12px var(--font-mono);
                    }
                }

                .title {
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    font: bold 12px var(--font-mono);
                }

                ul {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;

                    li {
                        overflow: hidden;
                        padding: 4px 0px;
                        color: #aaaaaa;
                        font: normal 10px var(--font-mono);

                        b {
                            color: #dedede;
                        }
                    }
                }
            }
        }
    }

    /* ---- ---- Páginas ---- ---- */

    .lyrics-page {

        .lyrics,
        .composers {
            width: 100% !important;
        }
    }

    // ---- ---- components | common ---- ---- //

    .banner {
        height: calc(100vh - 110px) !important;

        .banner-box {
            flex-direction: column-reverse;

            .banner-box-flex {
                overflow: hidden;
                margin: 0 !important;
                width: 100% !important;

                .banner-box-data {

                    h2 {
                        font-size: 30px;
                    }

                    h3 {
                        font-size: 16px;
                    }
                }

                .banner-box-action {

                    .primary-button {
                        border-color: var(--inactive-color);
                    }
                }

                .banner-box-image {
                    width: calc(100% - 80px) !important;
                }

                &:nth-child(2) {
                    padding-bottom: 20px;
                }
            }
        }
    }

    .music-card,
    .music-card-load,
    .video-card,
    .video-card-load,
    .photo-card,
    .photo-card-load,
    .product-card,
    .product-card-load {
        grid-column: span 12 !important;
    }

    .photo {

        .content {

            img {
                width: 100%;
                height: unset !important;
            }
        }
    }

    .product {

        .product-pictures {
            flex-direction: column !important;

            .product-gallery {
                width: 100%;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(12, 1fr);

                .product-photo {
                    grid-column: span 3;
                    grid-row: span 4;

                    &:active {
                        border: 1px solid var(--border-color);
                    }
                }
            }
        }
    }

    .no-found {
        min-height: 50vh !important;

        .title-no-found {
            font-size: 200px !important;
        }

        .text-no-found {
            font-size: 13px !important;
        }
    }

    .music-page {

        .music-page-image {

            .music-cover-wrapper {
                width: 100% !important;
            }

            .music-page-grid {
                width: 100% !important;
            }
        }

        .music-page-data {

            .title {
                font-size: 20px;
            }
        }
    }

    .image-wrapper {

        .image-wrapper-content {

            .wrapped-image {
                width: 100%;
                height: auto !important;
            }
        }
    }

    .video-modal {

        div {
            width: calc(100% - 40px);
        }

        .video-player {
            width: 100% !important;
            height: 214px !important;
        }

        .video-player-text {
            width: max-content;
        }
    }
}

// ---- ---- responsive (extra) ---- ---- //

@media screen and (max-width: 480px) {

    .product-card {
        .product-details {
            .product-details-title {
                font-size: 18px !important;
            }

            .product-details-price {
                font-size: 14px !important;
            }
        }
    }

    .music-page {
        flex-direction: column !important;

        .music-page-image {

            .music-cover {
                width: 100% !important;
            }
        }

        .music-page-data {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 399px) {

    // ---- ---- components | layout ---- ---- //

    .footer {

        .footer-newsletter {
            display: none !important;
        }
    }
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@import url(./fonts.css);
@import url(./pages.css);
@import url(./global.css);
@import url(./buttons.css);